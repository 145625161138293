import React from "react"

const Map = () => {
  return (
    <>
      <section
        id="contact"
        className="footer-info white-bg"
        // style={{ paddingTop: 300, paddingBottom: 300 }}
      >
        <iframe
          title="QUEUE Headquarters"
          className="map-responsive"
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
          marginHeight="50"
          marginWidth="50"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.505427847644!2d121.55546695161392!3d25.05085288388577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ab224e980d7b%3A0xadbe2519bb8a329a!2zUVVFVUUgUE9T57O757Wx!5e0!3m2!1sen!2stw!4v1597998127254!5m2!1sen!2stw"
          style={{ border: 0 }}
          allowFullScreen
        />
      </section>
    </>
  )
}

//<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.505427847644!2d121.55546695161392!3d25.05085288388577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ab224e980d7b%3A0xadbe2519bb8a329a!2zUVVFVUUgUE9T57O757Wx!5e0!3m2!1sen!2stw!4v1597998127254!5m2!1sen!2stw" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

//https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14459.562662356291!2d121.54188649031013!3d25.03778424352811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abeaee6a45a5%3A0xffa0ca2868dabe26!2sNo.%20112%2C%20Guangfu%20North%20Road%2C%20Songshan%20District%2C%20Taipei%20City%2C%20105!5e0!3m2!1sen!2stw!4v1589344226485!5m2!1sen!2stw"
export default Map
